import React from 'react';
import Header from '../../components/header/header';
import "./register.scss";


const RegisterProcess = () => {
    return (
        <div className='register'>
            <div className="header-register-doc">
                <Header />
            </div>
            <div className='register-container'>
                <div className="container-fluid css-qs8l1l es1maqa0">
                    <div className="columns is-multiline is-centered">
                        <div className="column is-8-tablet is-6-desktop is-5-fullhd">
                            <section className="css-1bogmwi eb7amyj1">
                                <div className="columns is-gapless is-vcentered is-mobile">
                                    <div className="column is-one-third">
                                        <a role="button" id="steps-back" tabIndex="0" className="css-xd0j1g emky6ke1">
                                            <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIxNiAxMiAxMiAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT44MUM4REUxMC1DM0YyLTRBMkYtQTM3NS1EMTUwMDdBNjVFRDI8L3RpdGxlPgogICAgPGcgaWQ9Ik1vYmlsZS1XZWItSW5zdXJhbmNlLUZsb3ciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzMC4wMy4wMC0tLUluc3VyYW5jZS1Gb3JtLUV4cGFuZGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC0xNjIuMDAwMDAwKSIgc3Ryb2tlPSIjMDA3NUU0IiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUGF0aC0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgMTYyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBvbHlsaW5lIHBvaW50cz0iMjUuNzUgMTQuNSAxOC4yNSAyMiAyNS43NSAyOS41Ij48L3BvbHlsaW5lPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K"
                                                alt="back" className="css-1yft4at emky6ke0" />
                                        </a>
                                        <span className="css-1i576z1 emky6ke2">5 of 6</span>
                                    </div>
                                    <div className="column">
                                        <div className="css-1yb974r e1m52mtu0">
                                            <div className="timer-text">We’re holding your appointment:<span className="timer-widget">34:25</span></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section role="main" className="css-10j4bz8 eqj76p80">
                                <div className=" css-1dpzekd etq995y6">
                                    <div className="columns is-variable is-1 is-mobile">
                                        <div className="column is-one-fifth-mobile is-one-fifth-tablet is-one-fifth-desktop">
                                            <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px', position: 'relative', maxWidth: '100%' }}>
                                                <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px', maxWidth: '100%' }}>
                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2792%27%20height=%2792%27/%3e"
                                                        style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px' }} />
                                                </span>
                                                <img data-testid="avatar" alt="Doctor" srcSet="/_next/image/?url=https%3A%2F%2Fstatic.plushcare.com%2Fdoctors%2Fprod%2Fjasminakrstic.jpg&amp;w=96&amp;q=75 1x, /_next/image/?url=https%3A%2F%2Fstatic.plushcare.com%2Fdoctors%2Fprod%2Fjasminakrstic.jpg&amp;w=256&amp;q=75 2x"
                                                    src="https://static.plushcare.com/doctors/prod/jasminakrstic.jpg" decoding="async" data-nimg="intrinsic"
                                                    className="css-1q9rfie em14a0u0" style={{ position: 'absolute', inset: 0, boxSizing: 'border-box', padding: '0px', border: 'none', margin: 'auto', display: 'block', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                            </span>
                                        </div>
                                        <div className="column is-four-fifths-mobile is-four-fifths-tablet is-four-fifths-desktop">
                                            <div className="doctor-name">Dr. Jasmina Krstic</div>
                                            <div className="css-gl2qrx etq995y3">
                                                <div data-testid="rating" className="css-5nwnwu ec6wh110"></div>
                                                <span className="css-cjt61t etq995y0">4.6</span>
                                            </div>
                                            <div className="css-1q7wp66 etq995y5">
                                                <svg width="16px" height="16px" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect y="4.61539" width="13.8462" height="1.15385" fill="#212529"></rect>
                                                    <mask id="path-2-inside-1" fill="white">
                                                        <rect x="3.46143" width="1.15385" height="3.46154" rx="0.5"></rect>
                                                    </mask>
                                                    <rect x="3.46143" width="1.15385" height="3.46154" rx="0.5" stroke="#212529" stroke-width="1.15385" mask="url(#path-2-inside-1)"></rect>
                                                    <mask id="path-3-inside-2" fill="white">
                                                        <rect x="9.23096" width="1.15385" height="3.46154" rx="0.5"></rect>
                                                    </mask>
                                                    <rect x="9.23096" width="1.15385" height="3.46154" rx="0.5" stroke="#212529" stroke-width="1.15385" mask="url(#path-3-inside-2)"></rect>
                                                    <rect x="0.5" y="1.65384" width="12.8462" height="12.8462" rx="1.5" stroke="#212529"></rect>
                                                </svg>
                                                <span>July 3rd 2024</span>
                                            </div>
                                            <div className="css-1q7wp66 etq995y5">
                                                <svg width="16px" height="16px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g id="Select-Dentist" transform="translate(-336.000000, -1361.000000)" stroke="#212529" strokeWidth="1.2">
                                                            <g id="icon-7" transform="translate(336.000000, 1361.000000)">
                                                                <rect id="Rectangle" x="0.6" y="0.6" width="13.8" height="13.8" rx="1"></rect>
                                                                <rect id="Rectangle-Copy" x="5.2" y="4.2" width="4.6" height="4.6"></rect>
                                                                <rect id="Rectangle-Copy-2" x="4.6" y="5.8" width="2.8" height="1.2"></rect>
                                                                <rect id="Rectangle-Copy-3" x="5.6" y="7.8" width="2.8" height="1.2"></rect>
                                                                <rect id="Rectangle-Copy-4" x="4.6" y="9.8" width="2.8" height="1.2"></rect>
                                                                <rect id="Rectangle-Copy-5" x="5.6" y="11.8" width="2.8" height="1.2"></rect>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span>10:00 - 11:00 AM</span>
                                            </div>
                                            {/* <div className="css-8o8a4n etq995y1">Virtual Care</div> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="css-1et9we6 eqj76p80">
                                <div className="container-fluid css-1j0ejgu es1maqa0">
                                    <div className="columns is-multiline is-centered">
                                        <div className="column is-6-tablet is-5-desktop is-4-fullhd">
                                            <div className="e1m52mtu3">
                                                {/* <div className="css-1wubgwc eqj76p80">Please wait while we connect you with Dr. Krstic.</div> */}
                                                <div className="css-19i18f9 e1m52mtu2">
                                                    <div className="e1m52mtu4">
                                                        <div className="css-ds1u9t e1m52mtu5">
                                                            <div className="css-gmb2w8 e1m52mtu6"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="css-2axhro e1m52mtu8">
                                <div className="container-fluid css-1j0ejgu es1maqa0">
                                    <div className="columns is-multiline is-centered">
                                        <div className="column is-6-tablet is-5-desktop is-4-fullhd">
                                            <div className="e1m52mtu3">
                                                {/* <div className="css-1wubgwc eqj76p80">Video call started.</div> */}
                                                <div className="css-19i18f9 e1m52mtu2">
                                                    <div className="e1m52mtu4">
                                                        <div className="css-ds1u9t e1m52mtu5">
                                                            <div className="css-gmb2w8 e1m52mtu6"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterProcess;
