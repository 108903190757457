import React from "react";
import Header from "../components/header/header";
import Patientlogo from "../../assets/img/patient-icon.png";
import DoctorIcon from "../../assets/img/doctor-icon.png";
import HospitalIcon from "../../assets/img/hospital-icon.png";
import FacilitiesIcon from "../../assets/img/facilities-icon.png";
import Psychiatrists from "../../assets/img/doc-img.jpg";
import Therapists from "../../assets/img/doc-img2.jpg";
import Psychologists from "../../assets/img/doc-img3.jpg";
import ProcessOne from "../../assets/img/process1.jpg";
import ProcessTwo from "../../assets/img/process2.jpg";
import ProcessThree from "../../assets/img/process3.jpg";
import IOS from "../../assets/img/ios-btn.png";
import Andriod from "../../assets/img/android-btn.png";
import Twitter from "../../assets/img/twitter-icon.png";
import Insta from "../../assets/img/insta-icon.png";
import LinkedIn from "../../assets/img/linkedin-icon.png";
import YouTube from "../../assets/img/youtube-icon.png";
import Facebook from "../../assets/img/facebook-icon.png"
import "./home.scss";
const Home = () => {

    function gtag_report_conversion(e, url) {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        /* eslint-disable-next-line */
        gtag('event', 'conversion', {
            'send_to': 'AW-11266211333/7oIgCP3s5NUYEIX8kvwp',
            'event_callback': callback
        });
        return false;
    }

    return (
        <div className="home-container">
            <div className="header-component">
                <Header />
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="top-banner">
                                <div className="container">
                                    <div className="tb-content">
                                        <h1>
                                            Virtual Care<br />
                                            From Anywhere<br />
                                            Schedule Online Today
                                        </h1>

                                        <a href="https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1" className="button pri-button" style={{ color: '#fff' }} onClick={(e) => gtag_report_conversion(e, "https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1")}>Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container all-tabs">
                <div className="row row-eq-height"></div>
                <div className="col">
                    <div className="patient-tab">
                        <img src={Patientlogo} alt="patientlogo" />
                        <h3>Member/Patient</h3>
                        <p>
                            Access Licensed
                            Doctors<br /><br /><br />
                        </p>
                        <a href="#" className="button white-btn">Talk to a doctor</a>
                        <a href="#" className="button white-bdr-btn">Set up account</a>
                    </div>
                </div>
                <div className="col">
                    <div className="doc-tab">
                        <img src={DoctorIcon} alt="" />
                        <h3>Doctors/Providers</h3>

                        <p>
                            See how partnering with us
                            can benefit your organization.
                        </p>

                        <a className="button white-btn">BECOME A DOCTOR/PROVIDER</a>
                        <a href="/doctor" className="button white-bdr-btn">Doctor login</a>
                    </div>
                </div>
                <div className="col">
                    <div className="facilities-tab">
                        <img src={FacilitiesIcon} alt="" />
                        <h3>Clinics/Facilities</h3>
                        <p>Tell us about your need.<br />Contract for provider hours.<br /><br /></p>
                        <a href="#" className="button white-btn">NEED A PROVIDER</a>
                        <a href="#" className="button white-bdr-btn">Request a demo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="busines-tab">
                        <img src={HospitalIcon} alt="" />
                        <h3>Businesses</h3>
                        <p>
                            See how partnering with us
                            canbenefit your organization.<br /><br />
                        </p>
                        <a href="#" className="button white-btn">PARTNER WITH US</a>
                        <a href="#" className="button white-bdr-btn">Learn more</a>
                    </div>
                </div>
            </div>
            <div className="price-banner-bx">
                <div className="price-banner"> </div>
                <div className="container">
                    <div className="price-bnr-cntnt col-sm-5">
                        <h1>Pricing & Plans</h1>
                        <p>
                            Most Health Insurance Plans are Accepted
                        </p>
                        <div className="wthot-insu">
                            <h3>Rate Without Insurance</h3>
                            <h4>INITIAL VISIT:</h4>
                            <div className="rate"><span className="rate-head">Doctors:</span>  $300</div>
                            <div className="rate"><span className="rate-head">NP/PA:</span> $200</div>
                            <div className="rate"><span className="rate-head">Therapist:</span> $100</div><br />

                            <h4>FOLLOW UP VISITS:</h4>
                            <div className="rate"><span className="rate-head">Doctors:</span> $100</div>
                            <div className="rate"><span className="rate-head">NP/PA:</span> $100</div>
                            <div className="rate"><span className="rate-head">Therapist:</span> $100</div>
                            <a href="#" className="bk-apint-txt">Book an Appointment</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* price tabs */}
            <div className="container price-tabs">
                <div className="row row-eq-height">
                    <div className="col">
                        <div className="urgent-care-tab">
                            <header>Psychiatry Adult</header>
                            <h5>Initial/Follow Up Rates</h5>
                            <div className="rate"><span className="rate-head">Doctors:</span> $300<span className="slash">/</span>$100</div>
                            <div className="rate"><span className="rate-head">NP/PA:</span> $200<span className="slash">/</span>$100</div>
                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>Depression</li>
                                        <li>Anxiety</li>
                                        <li>Sleep Disorders</li>
                                        <li>Panic Disorders</li>
                                        <li>Eating Disorders</li>
                                        <li>OCD</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>ADHD</li>
                                        <li>Bipolar Disorder</li>
                                        <li>Consultations</li>
                                        <li>Meds Renewals</li>
                                        <li>2nd Opinions</li>
                                        <li>Many more...</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-act"><a href="https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1" className="button pri-button">Get Started</a></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="psychiatry-tab ps-child">
                            <header>Psychiatry Child</header>
                            <h5>Initial/Follow Up Rates</h5>
                            <div className="rate"><span className="rate-head">MD:</span> $300<span className="slash">/</span>$100</div>
                            <div className="rate"><span className="rate-head">NP/PA:</span> $200<span className="slash">/</span>$100</div>
                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>Depression</li>
                                        <li>Panic Disorders</li>
                                        <li>Behavior Problems</li>
                                        <li>School Problems</li>
                                        <li>Aggression</li>
                                        <li>Many more...</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>ADHD</li>
                                        <li>Anxiety</li>
                                        <li>Bipolar Disorder</li>
                                        <li>Grief Issues</li>
                                        <li>OCD</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-act"><a href="https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1" className="button pri-button">Get Started</a></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="counseling-tab adult-tab">
                            <header>ADULT THERAPY</header>
                            <div className="rate"><span className="rate-head">Therapist:</span> $100</div>
                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>Stress</li>
                                        <li>Anxiety</li>
                                        <li>Depression</li>
                                        <li>Sleep Disorders</li>
                                        <li>PTSD</li>
                                        <li>OCD</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>Self-Esteem</li>
                                        <li>Body Dysmorphia</li>
                                        <li>Relationships</li>
                                        <li>Grief</li>
                                        <li>Anger</li>
                                        <li>2nd Opinions</li>
                                        <li>Many more...</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-act"><br /><a style={{ marginTop: 8 }} href="https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1" className="button pri-button">Get Started</a></div>
                        </div>
                    </div>
                </div>
                <div className="row row-eq-height">
                    <div className="col">
                        <div className="urgent-care-tab">
                            <header>Teen Counseling</header>
                            <div className="rate"><span className="rate-head">Therapist:</span> $100</div>
                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>ADHD</li>
                                        <li>Aggression</li>
                                        <li>Behavior Problems</li>
                                        <li>School Problems</li>
                                        <li>Acting Out</li>
                                        <li>Many more...</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>Depression</li>
                                        <li>Anxiety</li>
                                        <li>Bipolar Disorder</li>
                                        <li>Grief</li>
                                        <li>2nd Opinions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-act"><br /><a href="#" className="button pri-button">Get Started</a></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="psychiatry-tab intake">
                            <header>Intake Assessment</header>
                            <div className="rate" style={{ marginBottom: 10 }}><span className="rate-head">Fee:</span> $99</div>
                            <h5>If you are not sure of what type of services you should obtain, an Intake Assessor can help you with this one time assessment.</h5>
                            <div className="price-act"><br /><br /><br /><br /><br /><a style={{ marginTop: 10 }} href="#" className="button pri-button">Get Started</a></div>
                        </div>
                    </div>
                    <div className="col card-col"></div>
                </div>
            </div>
            <div className="doc-banner-bx">
                <div className="doc-banner"> </div>
                <div className="container">
                    <div className="doc-bnr-cntnt col-sm-4">
                        <h1>Our Top Rated<br />Psychiatrists<br />Therapists <br />Psychologists</h1>
                        <p className="doc-banner-text">See Our Trained, Experienced
                            Your State Licensed Psychiatrists
                            Therapists, Psychologists & other
                            Health Care Professionals</p>
                        <a href="#" className="see-more-doc">See These Providers From Your State</a>
                    </div>
                </div>
            </div>
            {/* our psychiatrists */}
            <div className="container doc-tabs">
                <div className="row">
                    <div className="col">
                        <div className="doc-sec">
                            <div className="doc-img"><img src={Psychiatrists} alt="" /></div>
                            <div className="doc-spec">Our Psychiatrists</div>
                            <a href="#" className="doc-link">Read more</a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="doc-sec">
                            <div className="doc-img"><img src={Therapists} alt="" /></div>
                            <div className="doc-spec">Our Therapists </div>

                            <a href="#" className="doc-link">Read more</a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="doc-sec">
                            <div className="doc-img"><img src={Psychologists} alt="" /></div>
                            <div className="doc-spec">Our Psychologists</div>
                            <a href="#" className="doc-link">Read more</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* want to became doctor */}
            <div className="container become-doc">
                <div className="become-doc-txt">Want to become a <strong>VirtualPsych</strong> doctor?</div>
                <a className="button pri-button">Learn More</a>
            </div>
            {/* visits */}
            <div className="app-process">
                <div className="container">
                    <h1>What to expect during your visits</h1>
                    <div className="row">
                        <div className="col">
                            <div className="process-text">
                                Search Provider According to Your Need<br /><br />
                            </div>
                            <img src={ProcessOne} alt="" />
                        </div>
                        <div className="col">
                            <div className="process-text">
                                Select Available Healthcare Provider<br /><br />
                            </div>
                            <img src={ProcessTwo} alt="" />
                        </div>
                        <div className="col">
                            <div className="process-text">
                                Start A Call With a Healthcare Provider<br /><br />
                            </div>
                            <img src={ProcessThree} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* app-banner */}
            <div className="app-bx">
                <div className="app-banner"> </div>
                <div className="container">
                    <div className="app-bnr-cntnt">
                        <h1>Care On The GO</h1>
                        <h4>Download App</h4>
                        <div className="app-download-link">
                            <a href="#"><img src={IOS} alt="" /></a>
                            <a href="#"><img src={Andriod} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
            <footer className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <ul>
                                <li><strong>LEARN MORE</strong></li>
                                <li><a href="#">About VirtualPsych Health</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="https://angel.co/jobs">Careers</a></li>
                                <li><a href="#">Newsroom</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <ul>
                                <li><strong>SOLUTIONS</strong></li>
                                <li><a href="#">VirtualPsych for Businesses</a></li>
                                <li><a href="#">VirtualPsych for Health Systems</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <ul>
                                <li><strong>CONNECT WITH US</strong></li>
                                <li><a href="#">Become a Provider</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li><a href="#">Request a Demo</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="social-sec">
                    <a href="https://www.facebook.com/VirtualPsychFB/" className="social-icon"><img src={Facebook} alt="" /></a>
                    <a href="#" className="social-icon"><img src={Twitter} alt="" /></a>
                    <a href="#" className="social-icon"><img src={Insta} alt="" /></a>
                    <a href="#" className="social-icon"><img src={LinkedIn} alt="" /></a>
                    <a href="#" className="social-icon"><img src={YouTube} alt="" /></a>
                </div>
            </footer>
            {/* <div className="privacy-sec">
                <div className="container">
                    <a href="#">Privacy Policy</a>
                    <a href="/terms-condition">Terms & Conditions</a>
                    <a href="#">Notice of Non-Discrimination</a>
                </div>
            </div> */}
        </div>
    )
};

export default Home;