import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from "./app/home/home";
import ContactUs from "./app/contactus/contaactus";
import AboutDoctors from "./app/memeberinfo/aboutdoctors";
import WhatIsUse from "./app/memeberinfo/whatIcanUseForIT";
import WhoAreTheDoctors from "./app/memeberinfo/whoAreTheDoctors";
import Overview from "./app/overview/overview"
import MemberLogin from "./app/auth/memberlogin/memberlogin";
import UpcomingAppointment from './app/home/home-component';
import Register from './app/auth/memberlogin/register';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Members from './app/members/members';
import TermsCondition from './app/termsCondition/termCondition';
import ProviderContainer from './app/provider/providerContainer';
import Counselors from './app/counselors/counselors';
import ScheduleAppointment from './app/scheduleAppointment/scheduleAppointment';
import AppointmentRequest from './app/ApptRequest/apptRequest';
import Booking from './app/PlushcareBooking/plushcareBooking';
import BookiPayWitInsurance from './app/PlushcareBooking/payInsurance/payWithInsurance';
import PlushcareAppointment from './app/PlushcareBooking/appointments/appointments';
import RegisterProcess from './app/PlushcareBooking/register/register';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/how-virtulpsych-works" element={<AboutDoctors />} />
        <Route path="/what-can-i-use-it-for" element={<WhatIsUse />} />
        <Route path="/who-are-the-doctors" element={<WhoAreTheDoctors />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/member-login" element={<MemberLogin />} />
        <Route path="/upcoming-appointment" element={<UpcomingAppointment />} />
        <Route path="/register" element={<Register />} />
        <Route path="/members" element={<Members />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/find-counselor" element={<ProviderContainer />} />
        <Route path="/dayton-counseling" element={<Counselors />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
        <Route path="/appointment-request" element={<AppointmentRequest />} />
        <Route path="/booking/primary-care/method" element={<Booking />} />
        <Route path="/booking/primary-care/insurance" element={<BookiPayWitInsurance />} />
        <Route path="/booking/primary-care/appointments" element={<PlushcareAppointment />} />
        <Route path="/booking/primary-care/register" element={<RegisterProcess />} />



      </Routes>
    </BrowserRouter>
  );
}

export default App;


function Schedule() {
  window.location.replace('https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1');
  return null;
}